import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React, {createContext, useContext, useState} from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";

/**
 * @desc Dialog 공통사용
 */

interface DialogProps {
    title?: string; // 제목
    msg: string; // 메시지
    screenSize?: string; // 창크기
    closeText?: string; // 취소버튼 라벨명
    confirmText?: string; // 확인버튼 라벨명
    okCallback: () => void;
    cancelCallback?: () => void;
}

type DialogContextActions = {
    doShow: (data:DialogProps) => void;
    doClose: () => void;
};

const DialogContext = createContext({} as DialogContextActions);

interface DialogContextProviderProps {
    children: React.ReactNode;
}

const DialogProvider: React.FC<DialogContextProviderProps> = ({
                                                                      children,
                                                                  }) => {
    const [ open, setOpen ] = useState(false);
    const [ dialogInfo, setDialogInfo ] = useState<DialogProps>({
        title: '',
        msg: '',
        screenSize: 'md',
        closeText: '취소',
        confirmText: '확인',
        okCallback: () => {
            console.log('Dialog OK');
        }
    });

    const doShow = (data: DialogProps) => {
        if (data.msg) {
            setDialogInfo(data);
            setOpen(true);
        }
    };

    const doClose = () => {
        setOpen(false);
    }

    const doCloseHandler = () => {
        if (dialogInfo.cancelCallback) {
            dialogInfo.cancelCallback();
        } else {
            doClose();
        }
    };

    return (
        <DialogContext.Provider value={{ doShow, doClose }}>
            <Dialog
                fullScreen={useMediaQuery(dialogInfo.screenSize ? dialogInfo.screenSize : 'md')}
                open={open}
                onClose={doCloseHandler}
            >
                {/* 타이틀이 있을 경우 타이틀 표시 */}
                { dialogInfo.title && <DialogTitle>{ dialogInfo.title }</DialogTitle>}
                <DialogContent>
                    {/* 메시지 */}
                    <DialogContentText>{ dialogInfo.msg }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* 취소 */}
                    <Button autoFocus onClick={doCloseHandler}>{dialogInfo.closeText}</Button>
                    {/* 확인 */}
                    <Button onClick={dialogInfo.okCallback} autoFocus>{dialogInfo.confirmText}</Button>
                </DialogActions>
            </Dialog>
            {children}
        </DialogContext.Provider>
    );
};

const useDialog = (): DialogContextActions => {
    const context = useContext(DialogContext);

    if (!context) {
        throw new Error('useDialog must be used within an DialogProvider');
    }

    return context;
};

export { DialogProvider, useDialog };