/**
 * FE 공통 URL
 */
export const commonUrl = {
    hrAdminUrl: 'https://papa.hr.crew-admin.papa-service.com',
    hrAdminDevUrl: 'https://dev.papa.hr.crew-admin.papa-service.com',
    hrCrewWebUrl: 'https://papa.hr.crew-webview.papa-service.com',
    hrCrewWebDevUrl: 'https://dev.papa.hr.crew-webview.papa-service.com',
};

/**
 * FE 공통 API
 */
export const commAPI = {
    codeList: '/api/code/list', // 공통코드
};

/**
 * 기준코드 관리
 */
export const standardAPI = {
    base: '/api/shift/standard', // 기준코드정보
    excelList: '/api/shift/standard-excel', // 엑셀리스트
    status: '/api/shift/standard-status', // 기준코드 상태변경
    select: '/api/shift/standard-select' // 기준코드 Select 리스트
}

/**
 * 차수 관리
 */
export const orderAPI = {
    base: '/api/shift/order', // 차수정보
    excelList: '/api/shift/order-excel', // 엑셀리스트
    status: '/api/shift/order-status', //
    select: '/api/shift/order-select'
}

/**
 * 근무조 관리
 */
export const shiftAPI = {
    base: '/api/shift/shift',
    excelList: '/api/shift/shift-excel',
    status: '/api/shift/shift-status',
    select: '/api/shift/shift-select'
}

/**
 * 근무조 맵핑 관리
 */
export const mappingAPI = {
    base: '/api/shift/mapping',
    excelList: '/api/shift/mapping-excel',
    status: '/api/shift/mapping-status',
    driverVisible: '/api/shift/mapping-visible'
}

/**
 * 크루 근무조 관리
 */
export const crewMappingAPI = {
    base: '/api/shift/crew-mapping',
    excelList: '/api/shift/crew-mapping-excel',
    crewShift: '/api/shift/crew-shift'
}

/**
 * 크루 근무일정 관리
 */
export const crewScheduleAPI = {
    base: '/api/shift/crew-schedule',
    excelList: '/api/shift/crew-schedule-excel'
}

/**
 * 크루 인사정보 관리
 */
export const crewAPI = {
    base: '/api/employees/info',
    excelList: '/api/employees/info-excel',
    info: '/api/employees/crew',
    standard: '/api/admin/standards/crew'
}

/**
 * 크루 근태 관리
 */
export const crewSchedulerAPI = {
    base: '/api/employees/scheduler',
    confirmBase: '/api/employees/scheduler-confirm',
    excelList: '/api/employees/scheduler-confirm-excel', // '/api/employees/scheduler-all',
    behaviorList: '/api/employees/driver-behavior',
    workCheck: '/api/employees/work-check'
}

/**
 * 휴가 신청서 관리
 */
export const workFlowAPI = {
    base: '/api/employees/work-flow-list',
    vacation: '/api/employees/vacation-list',
    vacReturn: '/api/workflow/vacation-return',
    vacApprove: '/api/workflow/vacation-approve',
    annual: '/api/employees/annual',
    vacRequest: '/api/employees/vacation-request',
    file: '/api/file/upload',
    vacRequestList: '/api/employees/vacation-request-list',
    commentSave: '/api/admin/workflows/comments',
    vacCancelApprove: '/api/workflow/wfmaster-approve'
}

export const vacationAPI = {
    base: '/api/admin/vacations',
    excelList: '/api/admin/vacations/excel',
    cancel: '/api/employees/vacation-cancel'
};

/**
 * 특별 근무 관리
 */
export const extraWorkAPI = {
    base: '/api/shift/extra-work',
    crewStatus: '/api/shift/extra-work-status-apply'
}
