/**
 * FE 공통 PAGE URL
 */

/**
 * 기준코드관리
 */
export const standards = {
    pattern: '/shift/standard',
    list: '/shift/standard/list',
    form: '/shift/standard/form'
};

/**
 * 차수관리
 */
export const orders = {
    pattern: '/shift/order',
    list: '/shift/order/list',
    form: '/shift/order/form'
};

/**
 * 근무조관리
 */
export const types = {
    pattern: '/shift/types',
    list: '/shift/types/list',
    form: '/shift/types/form'
};

/**
 * 근무조 맵핑
 */
export const mappings = {
    pattern: '/shift/mapping',
    list: '/shift/mapping/list',
    form: '/shift/mapping/form'
};

/**
 * 크루 근무조 관리
 */
export const crewMappings = {
    pattern: '/shift/crewMapping',
    list: '/shift/crewMapping/list',
    form: '/shift/crewMapping/form'
};

/**
 * 크루 근무일정 관리
 */
export const schedules = {
    pattern: '/shift/schedule',
    list: '/shift/schedule/list',
    form: '/shift/schedule/form'
};

/**
 * 특별근무관리
 */
export const extraWorks = {
    pattern: '/shift/extraWork',
    list: '/shift/extraWork/list',
    form: '/shift/extraWork/form'
};

/**
 * 인사정보관리
 */
export const infos = {
    pattern: '/employees/info',
    list: '/employees/info/list',
    form: '/employees/info/form'
};

/**
 * 크루근태관리
 */
export const schedulers = {
    pattern: '/employees/scheduler',
    list: '/employees/scheduler/list',
    form: '/employees/scheduler/form'
};

/**
 * 휴가신청서관리
 */
export const vacations = {
    pattern: '/employees/vacation',
    list: '/employees/vacation/list',
    form: '/employees/vacation/form',
    view: '/employees/vacation/view',
};