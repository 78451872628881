// ** Icon imports
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import SignpostOutlinedIcon from '@mui/icons-material/SignpostOutlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import BeachAccessOutlinedIcon from '@mui/icons-material/BeachAccessOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';

// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'
import {
  crewMappings,
  extraWorks,
  infos,
  mappings,
  orders,
  schedulers,
  schedules,
  standards,
  types, vacations
} from "../../store/pages";

/**
 * @desc 메뉴 리스트
 */
const navigation = (): VerticalNavItemsType => {
  return [
    {
      title: '대시보드',
      icon: SignpostOutlinedIcon,
      path: '/',
      pattern: null
    },
    {
      sectionTitle: '근무 관리'
    },
    {
      title: '기준코드 관리',
      icon: HubOutlinedIcon,
      path: standards.list,
      pattern: standards.pattern
    },
    {
      title: '차수 관리',
      icon: ManageHistoryOutlinedIcon,
      path: orders.list,
      pattern: orders.pattern
    },
    {
      title: '근무조 관리',
      icon: GroupsOutlinedIcon,
      path: types.list,
      pattern: types.pattern
    },
    {
      title: '근무조 맵핑',
      icon: AccountTreeOutlinedIcon,
      path: mappings.list,
      pattern: mappings.pattern
    },
    {
      title: '크루 근무조 관리',
      icon: EventNoteOutlinedIcon,
      path: crewMappings.list,
      pattern: crewMappings.pattern
    },
    {
      title: '크루 근무일정 관리',
      icon: CalendarMonthOutlinedIcon,
      path: schedules.list,
      pattern: schedules.pattern
    },
    {
      title: '특별 근무 관리',
      icon: EventOutlinedIcon,
      path: extraWorks.list,
      pattern: extraWorks.pattern
    },
    {
      sectionTitle: '인사 관리'
    },
    {
      title: '인사 정보 관리',
      icon: PermIdentityOutlinedIcon,
      path: infos.list,
      pattern: infos.pattern
    },
    {
      title: '크루 근태 관리',
      icon: WorkHistoryOutlinedIcon,
      path: schedulers.list,
      pattern: schedulers.pattern
    },
    {
      title: '휴가 신청서 관리',
      icon: BeachAccessOutlinedIcon,
      path: vacations.list,
      pattern: vacations.pattern
    },
  ]
}

export default navigation
