import {
    DATE_FORMAT_DEFAULT, DEFAULT_LOCALE,
    DEFAULT_TIMEZONE,
    LOCAL_STORAGE_TYPE,
    TIME_FORMAT_DEFAULT,
    TIMEZONE_UTC
} from "../constants/common";
import {getLocalStorage, setLocalStorage} from "./useLocalStorage";
import {AxiosError} from "axios";
import moment from "moment/moment";
import {utils, writeFile} from "xlsx";
import dayjs from 'dayjs';

import  duration from "dayjs/plugin/duration";
import  utc from "dayjs/plugin/utc";
import  timezone from "dayjs/plugin/timezone";
import isLeapYear from "dayjs/plugin/isLeapYear";

// DayJS 설정
dayjs.extend(isLeapYear); // 플러그인 등록
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.tz.setDefault(DEFAULT_TIMEZONE);
dayjs.locale(DEFAULT_LOCALE); // 언어 등록

/**
 * FE 공통
 */

/**
 * @function setCodeList
 * @desc 코드리스트 Local Storage 설정
 * @param data
 */
export function setCodeList(data:object) {
    if (data) {
        const codeList = JSON.stringify(data);
        setLocalStorage(LOCAL_STORAGE_TYPE.CODE, codeList);
    }
}

/**
 * @function getCodeList
 * @desc Local Storage 에서 코드 리스트를 가져와 그룹코드에 해당하는 코드 리스트만 반환
 * @param groupCode 그룹코드
 */
export function getCodeList(groupCode:string) {
    let res;
    const data:any = getLocalStorage((LOCAL_STORAGE_TYPE.CODE));
    if (data) {
        const dataList = JSON.parse(data);
        res = dataList[groupCode];
    } else {
        res = '';
    }

    return res;
}

/**
 * @desc 코드에 해당하는 코드명 반환
 * @param groupCode 그룹코드
 * @param code 코드
 */
export function getCodeName(groupCode:string, code:string) {
    const codeList = getCodeList(groupCode)
    for (const row of codeList){
        if (row.code == code){
            return row.name
        }
    }
}

/**
 * @function axiosError
 * @desc axios 오류 발생
 */
export function axiosError(res:AxiosError) {
    // res.response.status
    if (res.message) alert(res.message);
}

/**
 * @desc Response Error 메시지 반환
 * @param message
 */
export function getResponseErrorMsg(message) {
    if (typeof message === 'object') {
        return setMsgText('ext', message.name);
    } else {
        if (message) {
            return setMsgText('ext', message);
        }
    }
}

/**
 * @function doExcelDownload
 * @desc 엑셀 다운로드
 */
export function doExcelDownload(title?:string, dataList?:any) {
    const fileName = `${title}_${moment().unix()}.xls`;
    const ws = utils.json_to_sheet(dataList);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, title);
    writeFile(wb, fileName, {bookType: 'xls'});
}

/**
 * @function setMsgText
 * @desc 메시지 내용 반환
 * @param type 메시지 타입 (required | len | minLen | maxLen | rangeLen | date | ext | save | modify)
 * @param text
 * @param min
 * @param max
 */
export function setMsgText(type:string, text?:string, min?:number, max?:number) {
    let res = '';

    switch (type) {
        case 'required': res = `${text} 항목은 필수 입력 항목입니다.`; break;
        case 'len': res = `${text} 항목은 ${min}자로 입력해 주세요.`; break;
        case 'minLen': res = `${text} 항목은 최소 ${min}자 이상부터 입력 가능합니다.`; break;
        case 'maxLen': res = `${text} 항목은 최대 ${min}자까지 입력 가능합니다.`; break;
        case 'rangeLen': res = `${text} 항목은 최소 ${min}자 이상 최대 ${max}자 이하로 입력 가능합니다.`; break;
        case 'date': res = `${text} 항목의 날짜 형식이 정확하지 않습니다.`; break;
        case 'ext': res = text; break;
        case 'save': res = '정상적으로 등록되었습니다'; break;
        case 'modify': res = '정상적으로 수정되었습니다.'; break;
        case 'delete': res = '정상적으로 삭제되었습니다.'; break;
        case 'statusUpdate': res = '상태가 변경되었습니다.'; break;
        case 'success': res = '정상적으로 처리되었습니다.'; break;
        default: break;
    }

    return res;
}

/**
 * 날짜 계산
 * @param startDate 시작일자
 * @param endDate 종료일자
 */
export const calculateDateDifference = (startDate, endDate) => {
    // dayjs 객체로 변환
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    // 두 날짜 사이의 일수 차이 계산
    return end.diff(start, 'day') + 1;
};

/**
 * @desc 기본(초기) 날짜 반환 (1970-01-01)
 */
export function getDefaultDate()  {
    return dayjs(0).format(DATE_FORMAT_DEFAULT);
}

/**
 * @desc 기본(초기) 날짜 + 전달받은 날짜의 시간 조합
 * @param datetime dayjs 객체 or 일시 형식의 문자열
 */
export function getDefaultDateTimeByTime(datetime) {
    return dayjs.tz(`${getDefaultDate()} ${dayjs.tz(datetime).format(TIME_FORMAT_DEFAULT)}`, TIMEZONE_UTC);
}

/**
 * @desc 전달받은 날짜 + 전달받은 시간의 일시 조합
 */
export function doCreateDateTime(date, time) {
    return dayjs.tz(`${date} ${time}`, TIMEZONE_UTC);
}

/**
 * @desc 일자(YYYY-MM-DD) 형식 반환
 * @param datetime dayjs 객체 or 일시 형식의 문자열
 */
export function getDateToDateFormat(datetime) {
    return dayjs(datetime).format(DATE_FORMAT_DEFAULT);
}

/**
 * @desc 시간(HH:MM) 형식 반환
 * @param datetime dayjs 객체 or 일시 형식의 문자열
 */
export function getDateToTimeFormat(datetime) {
    return dayjs(datetime).format(TIME_FORMAT_DEFAULT);
}

/**
 * @desc 날짜 타입 형식 확인
 * @param datetime dayjs 객체
 */
export function doValidDateTime(datetime) {
    return !(isNaN(datetime.$d.valueOf()) === true || datetime.$d.valueOf() < 0 || datetime.$d.toString() === 'Invalid Date');
}

/**
 * @desc 기준일시 - 대상일시한 객체 반환
 * @param date 기준일시 dayjs 객체
 * @param subDate 대상일시 dayjs 객체
 */
export function doDateSubtractToDateTime(date, subDate) {
    return dayjs.tz(date - subDate, TIMEZONE_UTC);
}

/**
 * @desc 기준일시로 기준으로 대상일시와 차이 반환
 * @param date 기준일시
 * @param subDate 대상일시
 */
export function doDateDiff(date, subDate) {
    return dayjs.tz(date, TIMEZONE_UTC).diff(subDate);
}
/**
 * @desc 날짜에 일 더하여 반환
 * @param datetime dayjs 객체 or 일시 형식의 문자열
 * @param day 더할 일수
 */
export function doDateAddDay(datetime, day) {
    return dayjs.tz(datetime).add(day, 'days');
}

export function getDateToUtc(date) {
    return dayjs.tz(date, TIMEZONE_UTC);
}

/**
 * @desc 오늘일자 반환
 */
export function getTodayToDate() {
    return dayjs.tz().format(DATE_FORMAT_DEFAULT);
}

/**
 * @desc Unix 반환
 */
export function getDateUnix(datetime) {
    return dayjs.tz(datetime, TIMEZONE_UTC).unix();
}

/**
 * @desc 요일 반환
 * @param date
 */
export const dayOfWeek = (date:string) => {
    const day = dayjs(date);
    const dayOfWeek = day.format('dd')

    return(dayOfWeek);
}