import {Snackbar} from "@mui/material";
import React, {createContext, useContext, useEffect} from 'react';
import Alert, {AlertColor} from "@mui/material/Alert";
import {SNACKBAR_DURATION_DEFAULT} from "../../constants/common";

/**
 * @desc Snackbar 공통사용
 */

type SnackBarContextActions = {
    doShow: (text: string, typeColor: AlertColor) => void;
};

const SnackBarContext = createContext({} as SnackBarContextActions);

interface SnackBarContextProviderProps {
    children: React.ReactNode;
}

const SnackBarProvider: React.FC<SnackBarContextProviderProps> = ({
                                                                      children,
                                                                  }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>('');
    const [typeColor, setTypeColor] = React.useState<AlertColor>('info');

    const doShow = (text: string, color: AlertColor) => {
        if (text) {
            setMessage(text);
            setTypeColor(color);
            setOpen(true);
        }
    };

    const doClose = () => {
        setOpen(false);
    };

    return (
        <SnackBarContext.Provider value={{ doShow }}>
            <Snackbar
                open={open}
                autoHideDuration={SNACKBAR_DURATION_DEFAULT}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                message={message}
                onClose={doClose}>
                <Alert variant="filled" severity={typeColor ? typeColor : "error"} sx={{ width: '100%;', fontSize: '1rem', fontWeight: 600, padding: '15px' }}>
                    {message}
                </Alert>
            </Snackbar>
            {children}
        </SnackBarContext.Provider>
    );
};

const useSnackBar = (): SnackBarContextActions => {
    const context = useContext(SnackBarContext);

    if (!context) {
        throw new Error('useSnackBar must be used within an SnackBarProvider');
    }

    return context;
};

export { SnackBarProvider, useSnackBar };