// ** Next Imports
import Head from "next/head";
import { Router } from "next/router";
import type { NextPage } from "next";
import type { AppProps } from "next/app";

// ** Loader Import
import NProgress from "nprogress";

// ** Emotion Imports
import { CacheProvider } from "@emotion/react";
import type { EmotionCache } from "@emotion/cache";

// ** Config Imports
import themeConfig from "src/configs/themeConfig";

// ** Component Imports
import UserLayout from "src/layouts/UserLayout";
import ThemeComponent from "src/@core/theme/ThemeComponent";

// ** Contexts
import { SettingsConsumer, SettingsProvider } from "src/@core/context/settingsContext";

// ** Utils Imports
import { createEmotionCache } from "src/@core/utils/create-emotion-cache";

// ** React Perfect Scrollbar Style
import "react-perfect-scrollbar/dist/css/styles.css";

// ** Global css styles
import "../../public/styles/globals.css";
import "../../public/styles/mui-redefine.css";
import "../../public/styles/calendar-redefine.css";
import React, { useEffect } from "react";
import {axiosError, setCodeList} from "../hooks/useCommon";
import axios from "axios";

// ** dayjs
import dayjs from "dayjs";
import isLeapYear from "dayjs/plugin/isLeapYear"; // 윤년 판단 플러그인
import "dayjs/locale/ko";
import { DevSupport } from "@react-buddy/ide-toolbox-next";
import { ComponentPreviews, useInitial } from "../dev";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {SessionProvider} from "next-auth/react";
import {commAPI} from "../store/apis";
import {SnackBarProvider} from "../component/common/SnackbarContext";
import {DialogProvider} from "../component/common/DialogContext";
import {LoadingPageProvider} from "../component/loadings/LoadingPageContext";
import {DEFAULT_LOCALE, DEFAULT_TIMEZONE} from "../constants/common"; // 한국어 가져오기

// DayJS 설정
dayjs.extend(isLeapYear); // 플러그인 등록
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(DEFAULT_TIMEZONE);
dayjs.locale(DEFAULT_LOCALE); // 언어 등록

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache
}

const clientSideEmotionCache = createEmotionCache();

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on("routeChangeStart", () => {
    NProgress.start();
  });
  Router.events.on("routeChangeError", () => {
    NProgress.done();
  });
  Router.events.on("routeChangeComplete", () => {
    NProgress.done();
  });
}

/**
 * @desc 공통코드 리스트 조회 Process
 */
const getCodeList = async () => {
  await axios.get(commAPI.codeList)
    .then(response => {
      const res = response.data;
      // 성공일 경우
      if (res.result === true) {
        if (res.data && res.data.codeList) setCodeList(res.data.codeList);
      } else {
          if (typeof res.message === 'object') {
              alert(res.message.name);
          } else {
              if (res.message) {
                  alert(res.message);
              }
          }
      }
    })
    .catch(response => {
      axiosError(response);
    });
};

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  // Variables
  const getLayout = Component.getLayout ?? (page => <UserLayout>{page}</UserLayout>);

  useEffect(() => {
    getCodeList().then(); // 공통코드 조회
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{`${themeConfig.templateName}`}</title>
        <meta
          name="description"
          content={`${themeConfig.templateName}`}
        />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta charSet="utf8"/>
      </Head>
        <SessionProvider>
            <SettingsProvider>
                <SettingsConsumer>
                  {({ settings }) => {
                    return <ThemeComponent settings={settings}>{getLayout(<DevSupport ComponentPreviews={ComponentPreviews}
                                                                                      useInitialHook={useInitial}
                    >
                        <SnackBarProvider>
                            <DialogProvider>
                                <LoadingPageProvider>
                                    <Component {...pageProps} />
                                </LoadingPageProvider>
                            </DialogProvider>
                        </SnackBarProvider>
                    </DevSupport>)}</ThemeComponent>;
                  }}
                </SettingsConsumer>
              </SettingsProvider>
            </SessionProvider>
    </CacheProvider>
  );
};

export default App;
