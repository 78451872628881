import React, {createContext, useContext, useState} from 'react';
import Grid from "@mui/material/Grid";
import ButtonProgress from "./ButtonProgress";
import {deepPurple} from "@mui/material/colors";
import {CircularProgress} from "@mui/material";

/**
 * @desc Loading Page
 */
type LoadingPageContextActions = {
    doShow: () => void;
    doHide: () => void;
};

const LoadingPageContext = createContext({} as LoadingPageContextActions);

interface LoadingPageContextProviderProps {
    children: React.ReactNode;
}

const LoadingPageProvider: React.FC<LoadingPageContextProviderProps> = ({
                                                                      children,
                                                                  }) => {
    const [ isShow, setIsShow ] = useState(false);

    const doShow = () => {
        setIsShow(true);
    };

    const doHide = () => {
        setIsShow(false);
    }

    return (
        <LoadingPageContext.Provider value={{ doShow, doHide }}>
            <Grid position={"relative"}>
                {children}
                { isShow && (
                    <Grid sx={{
                        width: '100%',
                        height: '100%',
                        zIndex: 5000,
                        position: 'fixed',
                        background: '#5e5e5e80',
                        top: 0,
                        left: 0
                    }}>
                        <CircularProgress
                            size={80}
                            thickness={3}
                            sx={{
                                color: deepPurple.A700,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    </Grid>
                )}
            </Grid>
        </LoadingPageContext.Provider>
    );
};

const useLoadingPage = (): LoadingPageContextActions => {
    const context = useContext(LoadingPageContext);

    if (!context) {
        throw new Error('useLoadingPage must be used within an DialogProvider');
    }

    return context;
};

export { LoadingPageProvider, useLoadingPage };