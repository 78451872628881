/**
 * FE Constants
 */
/**
 * 페이징 기본
 */
export const OFFSET_DEFAULT:number = 30;
export const PAGE_DEFAULT:number = 1;

/**
 * 공통 텍스트
 */
export const NO_ROWS_LABEL = "검색된 데이터가 존재하지 않습니다.";
export const UNKNOWN_USER = "UNKNOWN";

/**
 * WIKI 문서 URL
 */
export const WIKI_DOC_URL = {
    SHIFT: 'https://catgo.atlassian.net/wiki/spaces/Product/pages/2479292535',
    EMPLOYEES: 'https://catgo.atlassian.net/wiki/spaces/Product/pages/2479325531'
}

/**
 * ENV
 */
export const ENV = {
    DEV: 'development',
    PROD: 'production'
}

/**
 * 리스트 공통
 */
export const LIST_SIZES = [10, 20, 30, 50, 100]

/**
 * 등록/수정 화면
 */
// 페이지 구분
export const PAGE_TYPE = {
    REGISTER: 'REGISTER',
    MODIFY: 'MODIFY'
}

/**
 * Local Storage
 */
export const LOCAL_STORAGE_TYPE = {
    CODE: 'codeList'
}

/**
 * SnackBar
 */
export const SNACKBAR_DURATION_DEFAULT = 3000;

/**
 * Date
 */
export const TIMEZONE_UTC = 'UTC';
export const DATE_FORMAT_DEFAULT = 'YYYY-MM-DD';
export const MONTH_FORMAT_DEFAULT = 'YYYY-MM';
export const TIME_FORMAT_DEFAULT = 'HH:mm';
export const DEFAULT_TIMEZONE = 'Asia/Seoul';
export const DEFAULT_LOCALE = 'ko';

/**
 * 코드 관련
 */
// 상태
export const STATUS = {
    ACTIVE: 'ACTIVE', // 활성화
    INACTIVE: 'INACTIVE' // 비활성화
}

/**
 * 사용자 유형
 */
export const USER_TYPE = {
    HRADMIN: 'HRADMIN', // HR 어드민
    DRIVER: 'DRIVER' // 크루
}

/**
 * 근무조 맵핑
 */
export const MAPPING_STATUS = {
    CREATED: 'CREATED', // 생성
    ASSIGNED: 'ASSIGNED', // 배정
    LIVE: 'LIVE', // 운영
    DONE: 'DONE' // 완료
}

/**
 * 재직 구분
 */
export const EMPLOY_TYPE = {
    WORK: 'WORK', // 재직
    ABSENCE: 'ABSENCE', // 휴직
    QUIT: 'QUIT' // 퇴직
}

/**
 * 근태 상태
 */
export const WORK_TYPE = {
    WORK: 'ATT001', // 근무
    CLOSED: 'ATT002', // 휴무
    BREAK: 'ATT003', // 휴가
    RECOGNITION: 'ATT004', // 인정근무
    EXTRAWORK: 'ATT005' // 특별근무
}

/**
 * 근태상세구분
 */
export const WORK_DETAIL_TYPE = {
    COMMON: 'COMMON', // 일반
    ANOMALY: 'ANOMALY' // 수정
}

/**
 * 스케줄타입
 */
export const SCHEDULE_TYPE = {
    EXPECTED: 'EXPECTED', // 예정
    CONFIRM: 'CONFIRM' // 확정
}

/**
 * 특별근무타입
 */
export const EXTRA_WORK_TYPE = {
    OPEN: 'OPEN', // 개설
    RECRUIT: 'RECRUIT', // 모집
    CLOSE: 'CLOSE', // 마감
    END: 'END' // 종료
}

/**
 * 특별근무크루신청코드
 */
export const EXTRA_WORK_CREW_STATUS_TYPE = {
    APPLY: 'APPLY', // 신청
    ACCEPT: 'ACCEPT', // 승인
    REJECT: 'REJECT' // 반려
}

/**
 * 전자결재문서타입
 */
export const  WORKFLOW_DOC_TYPE = {
    VACATION: 'VACATION', // 휴가
    VACATIONCANCEL: 'VACATIONCANCEL' // 휴가취소
}

/**
 * 전자결재상태코드
 */
export const WORKFLOW_STATUS = {
    DRAFT: 'DRAFT', // 기안
    ING: 'ING', // 진행
    COMPLETE: 'COMPLETE', // 완료
    CANCEL: 'CANCEL', // 취소
    REJECT: 'REJECT', // 반려
    ARBITRARY: 'ARBITRARY' // 전결
}

/**
 * 결재자진행코드
 */
export const WORKFLOW_LINE_STEP = {
    WAIT: 'WAIT', // 대기
    APPROVAL: 'APPROVAL', // 승인
    REJECT: 'REJECT', // 반려
    ARBITRARY: 'ARBITRARY', // 전결
}

/**
 * 휴가종류
 */
export const VACATION_TYPE = {
    VAC001: 'VAC001', // 유급연차
    VAC002: 'VAC002', // 무급연차
    VAC003: 'VAC003', // 경조사
    VAC004: 'VAC004', // 공가
    VAC005: 'VAC005', // 병가
    VAC006: 'VAC006', // 대체휴무
}

export const VACATION_STATUS_CODE = {
    APPLY: 'APPLY',
    ACCEPT: 'ACCEPT',
    REJECT: 'REJECT',
    CANCEL: 'CANCEL',
    ADMINCANCEL: 'ADMINCANCEL'
}

/**
 * 휴가종류에 따른 휴가상세 그룹코드
 */
export const VAC003_GROUP = 'HRI0006';
export const VAC004_GROUP = 'HRI0008';

/**
 * 연차종류
 */
export const ANNUAL_TYPE_GROUP = 'HRI0010';